*, *::before, *::after {
  box-sizing: border-box;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.highlight {
  background: radial-gradient(hsl(48, 100%, 90%), transparent);
  backdrop-filter: blur(6px);
}

.highlight-conditional {
  font-size: 16px;
  color: hsl(88, 63%, 26%);
  padding:8px;
  border: 1px solid hsl(51, 33%, 4%);
  margin-top: 2rem;
  animation: highlightIn 600ms;
  overflow: hidden;
}

/* Animation that starts with display none and display block after 300ms */

@keyframes highlightIn {
  0% {
    height: 0;
    border: none;
    padding:0;
    margin:0;
    opacity: 0;
  }
  50% {
    height: 0;
    border: none;
    padding:0;
    margin:0;
    opacity: 0;
  }
  100% {
    height: auto;
    padding:8px;
    border: 1px solid hsl(51, 33%, 4%);
    margin-top: 2rem;
    opacity: 1;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
